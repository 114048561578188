import React from "react";
import Seo from "../components/Seo/Seo";
import CheckAvailability from "./../components/Rebrand/CheckAvailability/CheckAvailability";
import HeroSwitch from "../components/Rebrand/Hero/HeroSwitch";
import ChooseYou from "../components/Rebrand/ChooseYou/ChooseYou";
import { Button, Section } from "../components/Core";
import { Container, Col, Row } from "react-bootstrap";

const Switch = () => {

    return (
        <div>
            <Seo page="switch" />
            <HeroSwitch />
            <CheckAvailability center bg="dark-bg" title={'Are we right up your street?'} />
            <Section>
                <Container className="mb-80">
                    <Row>
                        <Col sm={12} md={12} lg={4}>
                            <h2>As easy<br />as <b className="secondary-cl">1</b>, <b className="secondary-cl">2</b>, <b className="secondary-cl">3</b></h2>
                        </Col>
                        <Col sm={12} md={12} lg={8}>
                            <Row>
                                <Col sm={6} md={4} lg={4}>
                                    <h3>1.</h3>                                
                                    <h4>
                                        Check your<br />
                                        postcode<br />
                                        (is you in your<br />
                                        neighbourhood?)
                                    </h4>
                                </Col>
                                <Col sm={6} md={4} lg={4}>
                                    <h3>2.</h3>   
                                    <h4>
                                        Select your<br />
                                        product (be sure<br />
                                        to check out<br />
                                        our offers)
                                    </h4>
                                </Col>
                                <Col sm={6} md={4} lg={4}>
                                    <h3>3.</h3>   
                                    <h4>
                                        Get connected<br />
                                        (book a day<br />
                                        that works best)
                                    </h4>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col sm={12} md={12} lg={4}>
                            <h2>Easy switch<br />guide</h2>
                        </Col>
                        
                        <Col sm={6} md={4} lg={3} className="switch-provider flex flex-column justify-content-between">
                            <h4>How to switch<br />from another provider</h4>
                            <a className="button-link text-center" href="https://support.youfibre.com/knowledge-base/article/how-to-switch-to-you-from-virgin">
                                <Button className="blue-bg">See guide</Button>
                            </a>
                        </Col>
                    </Row>
                </Container>
            </Section>
            <ChooseYou /><CheckAvailability center bg="green-bg" title={'Are we right up your street?'} />
        </div>
    )
}
export default Switch